<template>
  <transition name="fade">
      <div v-if="!state.loaderEnabeled">
          <div v-if="state.tickets">
              <h4 class="card-title align-items-start flex-column mb-1 mx-5 text-primary">{{ state.myList.tot_ticket }} {{$t("Ticket")}}{{ Plur(state.myList.tot_ticket) }}</h4>
              <router-link :href="state.link && '#'" class="btn btn-flex btn-light btn-color-primary px-6" style="width: 100%"  to='/stilldeploy/ticket'>
                  <span class="svg-icon svg-icon-2x"><i class="bi bi-gear fs-1"></i></span>
                  <span class="d-flex flex-column align-items-start ms-2 mt-3 mb-3">
                      <span class="fs-7 text-gray-700">{{ state.myList.tot_ticket_en_cours }} {{$t("ticket")}}{{ Plur(state.myList.tot_ticket_en_cours) }} {{$t("en cours de traitement")}}</span>
                      <span class="fs-7 text-gray-700">{{ state.myList.tot_ticket_traites }} {{$t("ticket")}}{{ Plur(state.myList.tot_ticket_traites) }} {{$t("traité")}}{{ Plur(state.myList.tot_ticket_traites) }}</span>
                  </span>
              </router-link>
          </div>
          <div v-if="state.references">
              <h4 :class="'card-title align-items-start flex-column' + (state.tickets && ' mt-10' + ' mb-1') + ' mx-5 text-primary'">{{ state.myList.tot_stock }} {{$t("Référence")}}{{ Plur(state.myList.tot_stock) }} {{$t("en stock")}}</h4>
              <router-link :href="state.link && '#'" to='/stilldeploy/stock'>
                  <div class="btn btn-flex btn-light btn-color-primary px-6 " style="width: 100%">
                      <span class="svg-icon svg-icon-2x"><i class="bi bi-hdd-network fs-1"></i></span>
                      <span :class="'d-flex flex-column align-items-start ms-2' + (state.tickets && ' mt-3 mb-3')">
                          <span class="fs-7 text-gray-700">{{ state.myList.tot_dispo }} {{$t("référence")}}{{ Plur(state.myList.tot_dispo) }} {{$t("disponible")}}{{ Plur(state.myList.tot_dispo) }}</span>
                          <span class="fs-7 text-gray-700">{{ state.myList.tot_reappro }} {{$t("référence")}}{{ Plur(state.myList.tot_reappro) }} {{$t("en réappro.")}}</span>
                          <span class="fs-7 text-gray-700">{{ state.myList.tot_epuise }} {{$t("référence")}}{{ Plur(state.myList.tot_epuise) }} {{$t("épuisée")}}{{ Plur(state.myList.tot_epuise) }}</span>
                      </span>
                  </div>
              </router-link>
          </div>
          <div v-if="state.sites">
              <h4 class="card-title align-items-start flex-column mt-10 mb-1 mx-5 text-primary">{{ state.myList.tot_sites }} {{$t("Sites")}}</h4>
              <router-link :href="state.link && '#'" class="btn btn-flex btn-light btn-color-primary px-6" style="width: 100%" to='/stilldeploy/sites'>
              <span class="svg-icon svg-icon-3x"><i class="bi bi-pin-map fs-1"></i></span>
              <span class="d-flex flex-column align-items-start ms-2 mt-3 mb-3">
                  <template v-for="(item, index) in state.lSites" :key="index">
                  <span class="fs-7 text-gray-700" style="text-align: left">{{ item.tot }} {{$t("sites")}} "{{ item.adr_c_site_type }}"</span>
                  </template>
              </span>
              </router-link>
          </div>
      </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "customer-details",
  props: {
    link: Boolean,
    tickets: Boolean,
    references: Boolean,
    sites: Boolean,
  },
  setup(props) {

    const state = reactive({
      myList: [] as any,
      lSites: [] as any,
      loaderEnabeled: true,
      link: props.link,
      tickets: props.tickets,
      references: props.references,
      sites: props.sites,
      totalElements: 0,
    });
    const { t } = useI18n()

    const Plur = (item) => {
      if (item>0) return t('s');
    }

    onMounted(async () => {
        [state.tickets, state.references, state.sites].forEach(el => {
          state.totalElements = el ? state.totalElements + 1 : state.totalElements
        })
        let myList = await mAxiosApi.prototype.getAxios((!props.tickets ? '/getOverviewDeploy/stock' : '/getOverviewDeploy'))
        state.myList = myList.results;
        state.lSites = myList.lsites;
        state.loaderEnabeled = false;
    });

    return {
      state,
      Plur,
      model: {}
    };
  },
});
</script>
